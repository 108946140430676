import React from 'react';

import {
  get_season, get_curr_season
} from 'utils/api.js';

function wait(ms){
   var start = new Date().getTime();
   var end = start;
   while(end < start + ms) {
     end = new Date().getTime();
  }
}

let curr_season = '200';
export const CURRENT_SEASON = () =>{
  if (curr_season !== '200'){return curr_season;}
  get_season((response) => {
    curr_season = response.data.season;
    return curr_season;
  });
  if (curr_season === '200'){return wait(100);}
  if (curr_season === '200'){return wait(100);}
  if (curr_season === '200'){return wait(100);}
  if (curr_season === '200'){return wait(100);}
  return curr_season;
}



export const locationStates = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "District Of Columbia",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];

export const stringIsFloat = (value) => {
  if (value === "" ) {
    return true;
  }

  let endDot = false;
  if (value.slice(-1) === ".") {
    endDot = true;
    value = value.slice(0,-1);
  }

  if (endDot && value.indexOf('.') !== -1) {
    return false;
  }

  value = parseFloat(value);

  if (Number.isNaN(value)) {
    return false;
  }

  return true;
}

//used for getting lighter or darker shade color
export const shadeColor = (color, percent) => {

    var R = parseInt(color.substring(1,3),16);
    var G = parseInt(color.substring(3,5),16);
    var B = parseInt(color.substring(5,7),16);

    R = parseInt(R * (100 + percent) / 100);
    G = parseInt(G * (100 + percent) / 100);
    B = parseInt(B * (100 + percent) / 100);

    R = (R<255)?R:255;
    G = (G<255)?G:255;
    B = (B<255)?B:255;

    var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
    var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
    var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

    return "#"+RR+GG+BB;
}
