import React from 'react';
import Linkify from 'react-linkify';

import {
  Card, CardHeader, CardBody, CardSubtitle, CardText,
  Table,
  Collapse,
  Button,
  Badge,
  Row, Col,
  Alert
} from 'reactstrap';
import {Link,} from 'react-router-dom';

import LoadingModal from 'common/LoadingModal.js';
import LeaderboardLeagueContests from 'pages/user/LeaderboardLeagueContests.js';
import GolferModal from 'common/GolferModal.js';


import FontAwesome from 'react-fontawesome';
import numeral from 'numeral';
import { isMobPortrait, MobilePortrait, NotMobilePortrait } from 'utils/responsive.js';

import LeagueContestOAD from 'pages/user/LeagueContestOAD';

import {
  get_leaguecontest,
} from 'utils/api.js';
import { CURRENT_SEASON } from 'utils/constants';


export default class LeagueContestDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      season: props.season?props.season:CURRENT_SEASON(),
      leagueContest: null,
      contesttype: props.contesttype,
      golferModalOpen: false,
      golferModalGolferId: null,
    };

    this.fetchLeagueContestData = this.fetchLeagueContestData.bind(this);
    this.setSeason = this.setSeason.bind(this);
    this.toggleGolferModal = this.toggleGolferModal.bind(this);
    this.showGolferDetails = this.showGolferDetails.bind(this);

  }


  componentDidMount() {
    let season = CURRENT_SEASON();
    this.fetchLeagueContestData(this.props.activeLeague, this.props.season||season, this.state.contesttype);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeLeague !== this.props.activeLeague ||
      nextProps.season !== this.props.season || nextProps.contesttype !== this.props.contesttype) {
      this.fetchLeagueContestData(nextProps.activeLeague, nextProps.season, nextProps.contesttype);
    }else if (nextProps.activeLeague === this.props.activeLeague &&
      nextProps.season === this.props.season && nextProps.contesttype === this.props.contesttype &&
      nextProps.passedEventid !== (this.props.passedEventid||0)){
        let paramEvent = nextProps.passedEventid;
        if (nextProps.showLB && paramEvent && this.state.leagueContest && this.state.leagueContest.leaderboard && this.state.leagueContest.leaderboard.length > 0){
          const found = this.state.leagueContest.leaderboard.find(ele => ele.event_id === parseInt(paramEvent));
          if (found){
            this.props.toggleShowLCLeaderboard(parseInt(paramEvent),this.state.leagueContest.leagueContestId);
          }
        }
    }
  }

  fetchLeagueContestData(league, season, contesttype) {
    this.setState({season:season});
    get_leaguecontest(league, season, contesttype,
     ({leagueContest}) => {
      this.setState({
        isLoading:false, leagueContest,
      });
      //alert(this.props.showLB );
      //alert(leagueContest.live_events.length );
      let paramEvent = this.props.passedEventid;
      if (this.props.showLB && paramEvent && leagueContest.leaderboard && leagueContest.leaderboard.length > 0){
        const found = leagueContest.leaderboard.find(ele => ele.event_id === parseInt(paramEvent));
        if (found){
          this.props.toggleShowLCLeaderboard(parseInt(paramEvent),leagueContest.leagueContestId);
        }
      }

    }, (error) => {
      console.log(error);
      this.setState({
        leagueContest: null
      });
    });
  }

  setSeason(season) {
    this.fetchLeagueContestData(this.props.activeLeague, season);
  }

  toggleGolferModal() {
    this.setState({golferModalOpen: !this.state.golferModalOpen});
  }

  showGolferDetails(golferid) {
    this.setState({
      golferModalGolferId: golferid,
      golferModalOpen: true
    });
  }


  render() {



    if (this.props.lcEventid){
      return <>
          <LeaderboardLeagueContests activeLeague={this.props.activeLeague} eventid={this.props.lcEventid} contesttype={this.props.contesttype}
            leaguecontestid={this.props.lcLeaguecontestid}
            history={this.props.history}/>
        </>
    }


    if (this.state.contesttype === 'ONE_AND_DONE'){
      return(
          <LeagueContestOAD activeLeague={this.props.activeLeague} season={CURRENT_SEASON()}
          toggleShowLCLeaderboard={this.props.toggleShowLCLeaderboard}
          showLB={this.props.showLB} passedEventid={this.props.passedEventid}
          toggleShowAllLeagueConest={this.props.toggleShowAllLeagueConest}/>);
    }

    return (
      <>
          <LoadingModal modalScroll={true} isLoading={this.state.isLoading} />
          <Header toggleShowAllLeagueConest={this.props.toggleShowAllLeagueConest} />
          <SDAlert msg={this.state.leagueContest && this.state.leagueContest.msg} />
          <LeagueContestSummary leagueContest={this.state.leagueContest} showGolferDetails={this.showGolferDetails}/>
          <LeagueContestLeaderboard activeLeague={this.props.activeLeague} leagueContest={this.state.leagueContest} toggleShowLCLeaderboard={this.props.toggleShowLCLeaderboard}/>
          <UpComingEvents leagueContest={this.state.leagueContest}/>
          <GolferModal isOpen={this.state.golferModalOpen} toggle={this.toggleGolferModal} golferid={this.state.golferModalGolferId}/>
      </>
    );
  }
}

const Header = (props) => {
  return <Row noGutters className="pt-0 d-flex justify-content-center ml-2">
    <Col className="d-flex justify-content-between">
      <div className="d-flex justify-content-start">
          {/*<h3 className="my-2" style={{fontWeight:'bolder'}}>{this.state.leagueContests.name}</h3>*/}
          <h3 className="my-2" style={{fontWeight:'bolder'}}>{'Season Draft'}</h3>
      </div>
      <div className="d-flex justify-content-end mr-3">
          <Button outline size="sm" color="primary" className="my-2" onClick={props.toggleShowAllLeagueConest}>
          Back
          </Button>
      </div>
    </Col>
  </Row>
}


const SDAlert = (props) => {
  return props.msg !== undefined && props.msg &&
      <Row noGutters className="d-flex justify-content-center mt-2 mb-4">
        <Col lg="12">
          <Alert color="success" className="my-0">
            <strong>{'Commissioner Announcement'}</strong>
	          <div>
              <Linkify properties={{target: '_blank'}}>
                <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>{props.msg}</span>
              </Linkify>
	          </div>
          </Alert>
        </Col>
      </Row>
}

const LeagueContestSummary = (props) => {
  if (!props.leagueContest || props.leagueContest.summary.length === 0) {
    return null;
  }

  const summaryRows = [];
  props.leagueContest.summary.forEach((item, index) => {
    let details = props.leagueContest.details.filter((detail) => {return detail.leagueteam_id === item.leagueteam_id});
    summaryRows.push(<LeagueContestSummaryRow item={item} key={item.leagueteam_id} index={index} details={details} showGolferDetails={props.showGolferDetails} />);
  });

  return (
    <div>
      <Card>
        <CardHeader className="d-flex justify-content-between">
          <div className="d-flex justify-content-start" style={{fontWeight:'700', color:'green'}}>
            Leaderboard
          </div>
          <div className="d-flex justify-content-end">

          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="text-center">POS</th>
                <th>Team</th>
                <th>YTD Earnings</th>
                <th></th>
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {summaryRows}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

class LeagueContestSummaryRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({collapsed: !this.state.collapsed});
  }

  render() {

    let bodyrowclass = "completedEventRowBody";
    if (!this.state.collapsed) {
      bodyrowclass += " expanded";
    }

    return (
      <>
        <tr onClick={this.toggle} className="completedEventRowBody">
          <td className=" align-middle text-center">
            <span className=''>{this.props.index+1}</span>
          </td>
          <td className="">{this.props.item.team_name}</td>
          <td className="">${numeral(this.props.item.ytd_earnings).format('0,0')}</td>

          <td className="align-middle"><FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} /></td>
        </tr>
        <tr className={bodyrowclass}>
          <td colSpan="4" style={{padding: 0, borderTop: "none"}} className="align-middle">
            <Collapse isOpen={!this.state.collapsed} className="golfer-headshot-container-big-holderx">
              <LeagueContestDetailsRow details={this.props.details} showGolferDetails={this.props.showGolferDetails}/>
            </Collapse>
          </td>
        </tr>
      </>
    );
  }

}

const checkIfImageExists = (url, callback) => {
  const img = new Image();
  img.src = url;

  if (img.complete) {
    callback(true);
  } else {
    img.onload = () => {
      callback(true);
    };

    img.onerror = () => {
      callback(false);
    };
  }
}

class LeagueContestDetailsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      imageVerified: false,

      grpG1Cls: 'golfer-headshot-big',
      grpG2Cls: 'golfer-headshot-big',
      grpG3Cls: 'golfer-headshot-big',
      grpG4Cls: 'golfer-headshot-big',
      grpG5Cls: 'golfer-headshot-big',
      grpG6Cls: 'golfer-headshot-big',

    }
  }


  render() {
    const details = this.props.details;
    const IMAGE_BASE_URL = 'https://media.majorschallenge.com/headshots/';

    if (!this.state.imageVerified && details.length > 0){
      this.setState({'imageVerified':true});

      if (details.length > 0){
        checkIfImageExists(IMAGE_BASE_URL + details[0].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG1Cls: 'golfer-headshot-big golfer-name-initial'});}
        });
      }
      if (details.length > 1){
        checkIfImageExists(IMAGE_BASE_URL + details[1].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG2Cls: 'golfer-headshot-big golfer-name-initial'});}
        });
      }
      if (details.length > 2){
        checkIfImageExists(IMAGE_BASE_URL + details[2].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG3Cls: 'golfer-headshot-big golfer-name-initial'});}
        });
      }
      if (details.length > 3){
        checkIfImageExists(IMAGE_BASE_URL + details[3].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG4Cls: 'golfer-headshot-big golfer-name-initial'});}
        });
      }
      if (details.length > 4){
        checkIfImageExists(IMAGE_BASE_URL + details[4].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG5Cls: 'golfer-headshot-big golfer-name-initial'});}
        });
      }
      if (details.length > 5){
        checkIfImageExists(IMAGE_BASE_URL + details[5].golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png', (exists) => {
          if (!exists) {this.setState({grpG6Cls: 'golfer-headshot-big golfer-name-initial'});}
        });
      }
    }

    const golfers = details.map(({golfer_id, golfer_name, alternate_ind, winnings}, index) => {
      var names = golfer_name.split(' '),
        initials = names[0].substring(0, 1).toUpperCase(),
        shortName = initials + '. ';

      if (names.length > 1) {
          initials += names[names.length - 1].substring(0, 1).toUpperCase();
          shortName += names[names.length - 1];
      }

      let conCls =  'golfer-headshot-container-big';
      if (!isMobPortrait() && index > 0){ conCls = conCls + ' ml-3';}
      if (isMobPortrait() && (index > 0 && index !== 3)){ conCls= conCls + ' ml-4';}

      const bgCls = {backgroundImage: 'url(' + IMAGE_BASE_URL + golfer_name.replace(/[\W_]+/g,'').toLowerCase() + '.png)'};
      return (
        <div className={conCls} style={{zIndex:index}} onClick={() => this.props.showGolferDetails(golfer_id)}>
          {index === 0 && <div className={this.state.grpG1Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 1 && <div className={this.state.grpG2Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 2 && <div className={this.state.grpG3Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 3 && <div className={this.state.grpG4Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 4 && <div className={this.state.grpG5Cls} style={bgCls}><div>{initials}</div></div>}
          {index === 5 && <div className={this.state.grpG6Cls} style={bgCls}><div>{initials}</div></div>}
          {alternate_ind && <div className="golfer-headshot-alt"><div className="alt">{'ALT'}</div></div>}
          <div className="roster-headshots-name" style={{fontWeight:'400'}}>{shortName}</div>
          <div className="roster-headshots-name" style={{fontWeight:'400'}}>$ {numeral(winnings).format('0,0')}</div>
        </div>
      )
    });

    let first = golfers.slice(0, 3);
    let second = golfers.slice(3);
    //const mobMortrait = isMobPortrait();
    return (
      <div>
      <MobilePortrait>
        <div>
          <div className="d-flex justify-content-center" style={{height:'120px'}}>
            {first}
          </div>
          <div className="d-flex justify-content-center" style={{height:'120px'}}>
            {second}
          </div>
        </div>
      </MobilePortrait>
      <NotMobilePortrait>
        <div className="d-flex justify-content-center" style={{height:'150px'}}>
          {golfers}
        </div>
      </NotMobilePortrait>
    </div>
    );
  }
}

const LeagueContestLeaderboard = (props) => {
  if (!props.leagueContest || props.leagueContest.leaderboard.length === 0) {
    return null;
  }

  const summaryRows = [];
  let summary = props.leagueContest.leaderboard.filter((summ) => {return summ.rnk === 1});
  summary.forEach((item, index) => {
    let details = props.leagueContest.leaderboard.filter((detail) => {return detail.tournament === item.tournament && detail.rnk >= 1});
    summaryRows.push(<LeagueContestLeaderboardRow activeLeague={props.activeLeague}
        item={item} key={index} index={index} details={details}
        toggleShowLCLeaderboard={props.toggleShowLCLeaderboard}/>);
  });

  return (
    <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between px-2">
          <div className="d-flex justify-content-start" style={{fontWeight:'700', color:'green'}}>
            {'Tournament Results'}
          </div>
          <div className="d-flex justify-content-end">

          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="">Tournament</th>
                <th>Winner</th>
                <th className="text-center">Golfers</th>
                <th className="text-right">Earnings</th>
                <th></th>
                <NotMobilePortrait>
                <th></th>
                </NotMobilePortrait>
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {summaryRows}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}

class LeagueContestLeaderboardRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = { collapsed: true };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    this.setState({collapsed: !this.state.collapsed});
  }

  render() {

    let bodyrowclass = "completedEventRowBody";
    if (!this.state.collapsed) {
      bodyrowclass += " expanded";
    }
    const live = ["round1", "round2", "round3", "round4"].indexOf(this.props.item.tournament_status) !== -1 ? true : false;
    const open = ["open"].indexOf(this.props.item.tournament_status) !== -1 ? true : false;

    return (
      <>
        <tr  className="">
          <td className=" align-middle" onClick={this.toggle} colSpan={(open?"3":"1")}>
            {this.props.item.tournament}
            { live &&
              <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
            }
            { open &&
              <Badge color="info" style={{marginLeft: "0.5rem", marginTop: "0.3rem"}}> Open </Badge>
            }
          </td>
          {live &&
          <>
          <td className="align-middle text-right" colSpan="3">
            <div className="align-middle text-right">
              <Button size="sm" color="primary">
                <Link to={`/league/`+ this.props.activeLeague + `/sdcontest/event/`+ this.props.item.event_id} style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>Leaderboard</Link>
              </Button>
            </div>
          </td>
          <td className="align-middle text-right" colSpan="2">
          </td>
          </>
          }
          {open &&
            <>
              <td className="align-middle text-right" onClick={this.toggle}>
                Preview
              </td>
              <td className="align-middle" onClick={this.toggle}>
                <FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} />
              </td>
              <NotMobilePortrait>
                <td className="align-middle">
                  <Button size="sm" outline color="success" style={{width:'65px'}} >
                      <Link to={`/league/`+ this.props.activeLeague + `/sdcontest/event/`+ this.props.item.event_id} style={{color: "inherit", textDecoration: "none", cursor: "inherit"}}>Field</Link>
                  </Button>
                </td>
              </NotMobilePortrait>
            </>
          }
          {(!live && !open) &&
            <>
              <td className="align-middle text-nowrap" onClick={this.toggle}>
                {this.props.item.team_name}
              </td>
              <td className="text-center align-middle" onClick={this.toggle}>
                {this.props.item.golfer_count}
              </td>
              <td className="align-middle text-right" onClick={this.toggle}>
                ${numeral(this.props.item.earnings).format('0,0')}
              </td>
              <td className="align-middle" onClick={this.toggle}>
                <FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} />
              </td>
              <NotMobilePortrait>
                <td className="align-middle">
                  <Link to={`/league/`+ this.props.activeLeague + `/sdcontest/event/`+ this.props.item.event_id} className="btn btn-outline-success btn-sm">Results</Link>
                </td>
              </NotMobilePortrait>
            </>
          }
        </tr>
        <tr className={bodyrowclass}>
          <td colSpan="6" style={{padding: 0, borderTop: "none"}} className=" align-middle">
            <Collapse isOpen={(!this.state.collapsed && !live)} className="mx-4 mb-1">
              <LeagueContestLeaderboardDetailsRow details={this.props.details} />
              <MobilePortrait>
              <div className="align-middle text-center mt-2">
                <Button size="sm" outline color="primary" >
                  <Link to={`/league/`+ this.props.activeLeague + `/sdcontest/event/`+ this.props.item.event_id} >{open?'Field':'Full Results'}</Link>
                </Button>
              </div>
              </MobilePortrait>
            </Collapse>
          </td>
        </tr>
      </>
    );
  }

}

class LeagueContestLeaderboardDetailsRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    }
  }


  render() {
    const rows = this.props.details.map((detail, index) => {
      return (
        <tr key={index} className="">
          <td className="text-nowrap">{detail.team_name}</td>
          <td className="text-center">{detail.golfer_count}</td>
          <td className="">${numeral(detail.earnings).format('0,0[.]00')}</td>
        </tr>
      )
    });

    return (
      <Table className="contestLBDetailsTable" style={{}}>
        <thead>
          <tr className="contestLBDetailsHeader">
            <th className="text-nowrap">Team</th>
            <th className="text-center">Golfers</th>
            <th className="" style={{width:'70%'}}>Earnings</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    );
  }
}

const UpComingEvents = (props) => {
  if (!props.leagueContest){
    return null;
  }

  if (props.leagueContest.upcomingevents.length === 0) {
    return (
      <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between px-2">
          <div className="d-flex justify-content-start" style={{fontWeight:'700', color:'green'}}>
            {'Upcoming Events'}
          </div>
          <div className="d-flex justify-content-end">

          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <tbody style={{fontSize: ".9em"}}>
              <td>
                <CardSubtitle>No Events Scheduled</CardSubtitle>
                <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
                  <span>This league has no scheduled events for the rest of this season.</span>
                </CardText>
              </td>
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>);
  }

  const upcomingeventsRows = [];
  props.leagueContest.upcomingevents.forEach((item, index) => {
    upcomingeventsRows.push(
      <tr>
        <td>
          <CardSubtitle>{item.name}</CardSubtitle>
          <CardText className="d-flex align-items-center" style={{"color": "#aaa", "fontSize": "0.77rem"}}>
            <span className="pr-1">
              {item.dates} &bull; {item.course}.
            </span>
          </CardText>
        </td>
      </tr>
    );
  });

  return (
    <div>
      <Card className="my-4">
        <CardHeader className="d-flex justify-content-between px-2">
          <div className="d-flex justify-content-start" style={{fontWeight:'700', color:'green'}}>
            {'Upcoming Events'}
          </div>
          <div className="d-flex justify-content-end">

          </div>
        </CardHeader>
        <CardBody className="p-0">
          <Table responsive className="mb-0">
            <tbody style={{fontSize: ".9em"}}>
              {upcomingeventsRows}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  )
}
