import React from 'react';
import Linkify from 'react-linkify';

import {
  Alert,
  Container, Row, Col,
  Badge,
  Card, CardTitle, CardBody,
  Collapse,
  Table,
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';

import {NavLink} from 'react-router-dom';

import FontAwesome from 'react-fontawesome';

import { get_cupseasonids, get_cupstandings, get_cupevents } from 'utils/api.js';
import { CURRENT_SEASON } from 'utils/constants';

import 'pages/user/Cup.css';
import CupScoringInfoModal from 'common/CupScoringInfoModal';

export default class Cup extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cups:[],
      cupId: parseInt(props.match.params.cupid, 10),
      cupStandings: [],
      cupEvents: [],
      infoOpen: false,
      eventsOpen: false,
      standingsOpen: true,
      numWinners: 1,
      season: CURRENT_SEASON(),
      seasonDropdownOpen: false,
      selectedLeague: null,
    };
    this.fetchCupSeasonIds = this.fetchCupSeasonIds.bind(this);
    this.fetchCupData = this.fetchCupData.bind(this);
    this.setCupId = this.setCupId.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleSeasonDropdown = this.toggleSeasonDropdown.bind(this);
  }

  componentDidMount() {
    let paramCup = parseInt(this.props.match.params.cupid, 10);

    if (this.state.cupId !== paramCup) {
      this.setState({cupId: paramCup});
      return;
    }
    this.fetchCupSeasonIds();
    this.fetchCupData();

    if (this.props.activeLeague && this.props.me){
      this.props.me.leagues.forEach((league)=>{
        if (league.leagueid === this.props.activeLeague) { this.setState({selectedLeague: league}) };
      });
    }

  }
  componentDidUpdate(prevProps, prevState) {

    let paramCup = parseInt(this.props.match.params.cupid, 10);
      console.log('prevProps:' + paramCup);

    /*if (this.state.cupId !== paramCup) {
      this.setState({cupId: paramCup});
      return;
    }*/

    if (prevState.cupId !== this.state.cupId) {
      this.fetchCupData();
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.activeLeague && nextProps.me){
      nextProps.me.leagues.forEach((league)=>{
        if (league.leagueid === nextProps.activeLeague) { this.setState({selectedLeague: league}) };
      });
    }
  }

  fetchCupSeasonIds(){
    get_cupseasonids(
      this.state.cupId,
    ({success, error, cups, league}) => {
      if (success) {
        //console.log(cups);
        this.setState({cups: cups});
      } else {
        //alert(error);
      }
    }, (error) => {
      console.log(error);
    });

  }
  fetchCupData() {
    get_cupstandings(this.state.cupId,
     (cupStandings, numWinners, cupScoring2ndPhase, scoringMethod,cupScorePoints) => {
      this.setState({
        cupStandings,
        numWinners,
        cupScoring2ndPhase,
        scoringMethod,
        cupScorePoints,
      });
    }, (error) => {
      console.log(error);
      this.setState({
        cupStandings: [],
      });
    });

    get_cupevents(this.state.cupId,
     (cupEvents, league, cupMsg) => {
      this.setState({
        cupEvents,
        cupMsg:(cupMsg!=null?cupMsg:''),
      });

      if (this.props.activeLeague !== league) {
        this.props.selectLeague(league);
      }
    }, (error) => {
      console.log(error);
      this.setState({
        cupEvents: []
      });
    });
  }

  toggle(state) {
    this.setState({
      [state]: !this.state[state],
    })
  }

  toggleSeasonDropdown() {
    this.setState({
      seasonDropdownOpen: !this.state.seasonDropdownOpen
    });
  }

  setCupId(cupId, season){
    this.setState({cupId:cupId, season:season});
    //this.fetchCupData();
  }

  render() {
    return (
      <Container fluid style={{maxWidth: "1128px"}}>
        <Row>
          <Col xs="12" md={{size:"10", offset: "1"}} lg={{size:"8", offset:"2"}}>
            <CupInfo infoOpen={this.state.infoOpen} selectedLeague={this.state.selectedLeague} toggle={this.toggle} cupScoring2ndPhase={this.state.cupScoring2ndPhase}
            scoringMethod={this.state.scoringMethod} cupScorePoints={this.state.cupScorePoints}/>
            <CupAlert cupMsg={this.state.cupMsg}/>
            <CupEvents events={this.state.cupEvents} eventsOpen={this.state.eventsOpen} toggle={this.toggle} />
            <CupStandings data={this.state.cupStandings} standingsOpen={this.state.standingsOpen} toggle={this.toggle}
                        numWinners={this.state.numWinners} cupScoring2ndPhase={this.state.cupScoring2ndPhase}
                        scoringMethod={this.state.scoringMethod} cupScorePoints={this.state.cupScorePoints}
                        cups={this.state.cups} setCupId={this.setCupId}
                        season={this.state.season} seasonDropdownOpen={this.state.seasonDropdownOpen}
                        toggleSeasonDropdown={this.state.toggleSeasonDropdown}
                        selectedLeague={this.state.selectedLeague}/>
          </Col>
        </Row>
      </Container>
    );
  }
}

const CupInfo = (props) => {
  const cupName = (props.selectedLeague && props.selectedLeague.whiteLabel && props.selectedLeague.whiteLabel.contest_name)?props.selectedLeague.whiteLabel.contest_name:'Majors Challenge Cup';
  return(
  <Alert color="success" className="my-4">
    <div className="d-flex justify-content-between py-1 font-weight-bold" onClick={()=>{props.toggle('infoOpen')}}>
      {cupName}
      <FontAwesome size="lg" name={props.infoOpen ? "angle-up" : "angle-down"} />
    </div>
    <Collapse isOpen={props.infoOpen}>
      {props.cupScoring2ndPhase === 'OFF' &&
        <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>
          Welcome to the season long {cupName}. Your score is calculated by adding together results across multiple events throughout the season. In order to compete, you must use the same team name in all cup events chosen by the commissioner. If you miss an event, then your team will be cut. The Cup is a separate competition and results are independent of and not related to winners for any individual tournament.
        </span>
      }
      {props.cupScoring2ndPhase === 'ON' &&
        <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>
          Welcome to the season long {cupName}. In order to compete you must use the same team name in all cup events. The Cup is a separate competition and results are independent of and not related to winners for any individual tournament. Your commissioner has chosen <b>{props.scoringMethod}</b> as the scoring method for the season. For a description of scoring methods&nbsp;
          <CupScoringInfoModal embedIn={'UserCup'} cupScorePoints={props.cupScorePoints} scoringMethod={props.scoringMethod}/>
        </span>
      }
    </Collapse>
  </Alert>
)
}

const CupAlert = (props) => {
  return props.cupMsg !== undefined && props.cupMsg !== '' &&
      <Row noGutters className="d-flex justify-content-center">
        <Col lg="12">
          <Alert color="success" className="my-0">
            <strong>{'Commissioner Announcement'}</strong>
	          <div>
              <Linkify properties={{target: '_blank'}}>
                <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>{props.cupMsg}</span>
              </Linkify>
	          </div>
          </Alert>
        </Col>
      </Row>
}

class CupStandingsRow extends React.Component {
    constructor(props) {
      super(props);
      this.state = { collapsed: true };
      this.toggle = this.toggle.bind(this);
    }
    toggle() {
      this.setState({collapsed: !this.state.collapsed});
    }
    render() {
      const item = this.props.item;
      const index = this.props.index;
      const numWinners = this.props.numWinners;
      const color = (item.toPar !== null && item.toPar < 0) ? "#e74c3c" : "#515356";
      const oldCupScoring = (this.props.scoringMethod === 'Gross Score' || this.props.cupScoring2ndPhase === 'OFF')?true:false;
      const content = oldCupScoring?
                    (item.toPar === 0 ? "E" :
                    item.toPar === null ?
                      "-" : item.toPar > 0 ?
                          "+"+item.toPar : item.toPar):
                    (item.toPar === null ?
                              "-" : item.toPar);

      const todayColor = (item.live === true && item.today < 0) ? "#e74c3c" : "#515356";
      const todayContent = (item.live === false || item.today === null) ? '-' :
                           (item.today === 0 ? "E" :
                                                   item.today > 0 ? "+" + item.today : item.today);

      //RI#177 - The Cup - Phase 2 - strike through is not needed anymore
      const maybeStrike = oldCupScoring && item.out ? {textDecoration: ''} : {};

      //const rankClass = 'cupTeamRowRank' + (rank === '1' || rank === 'T1' ? ' winner' : '');
      const rankClass = 'cupTeamRowRank' + (index<numWinners ? ' winner' : '');
      //const rankClass = 'cupTeamRowRank' + index + '_' + props.numWinners +  '';
      const maybeRank = oldCupScoring && item.out ? undefined : <span className={rankClass}>{item.rank}</span>;

      let bodyrowclass = "completedEventRowBody";
      if (!this.state.collapsed) {
        bodyrowclass += " expanded";
      }

      let showTeam = true;
      if (this.props.selectedLeague && this.props.selectedLeague.whiteLabel && !this.props.selectedLeague.whiteLabel.use_team_names){
        showTeam = false;
      }

      return (
        <>
        <tr key={index} onClick={this.toggle} className={"cupTeamRowBody" + (item.usersRow ? ' personal' : '')}>
          <td className="cupTeamRowBase text-center">{maybeRank}</td>

          <td style={maybeStrike} className="cupTeamRowBase py-1">
            {!showTeam && <div>{item.owner}</div>}
            {showTeam && <>
            <div>{item.team}</div>
            <div style={{color: "gray", fontSize: ".8em"}}>{item.owner}</div>
            </>
            }
          </td>
          <td className="cupTeamRowBase text-center">{oldCupScoring && item.out ? 'CUT' : <span style={{color: color}}>{content}</span>}</td>
          <td className="cupTeamRowBase text-center">{oldCupScoring && item.out ? 'CUT' : <span style={{color: todayColor}}>{todayContent}</span>}</td>
          <td className="cupTeamRowBase text-center">{oldCupScoring && item.out ? '-' : item.behind}</td>
          <td className="cupTeamRowBase text-center">{item.events}</td>
          <td className="align-middle"><FontAwesome size="lg" name={this.state.collapsed ? "angle-down" : "angle-up"} /></td>
        </tr>
        <tr className={bodyrowclass}>
          <td colSpan="12" style={{padding: 0, borderTop: "none"}}>
            <Collapse isOpen={!this.state.collapsed} className="completedEntryCollapse">
              <CupEntries {...this.props.item} oldCupScoring={oldCupScoring}/>
            </Collapse>
          </td>
        </tr>
        </>
      )
    }
}

const CupEntries = (props) => {
  props.eventDetails.sort((a, b)=>{
    return new Date(b.event_date).getTime() - new Date(a.event_date).getTime();
  });

  const rows = props.eventDetails.map((entry, index) => {

    const toParColor = (entry.toPar !== null && entry.toPar < 0) ? "#e74c3c" : "#515356";
    const toParContent = entry.toPar === 0 ? "E" :
                    entry.toPar === null ? "-" :
                    entry.toPar > 0 ? "+" + entry.toPar :
                    entry.toPar;

    return (
      <tr key={entry.entry_id} className="completedEntryRow">
        <td className="text-center">{entry.place}</td>
        <td className="">{entry.event}</td>
        {props.oldCupScoring === false &&
          <td className="cupTeamRowBase text-center">{entry.score}</td>
        }
        <td className="cupTeamRowBase text-center">{<span style={{color: toParColor}}>{toParContent}</span>}</td>
        <td className="cupTeamRowBase text-center">{entry.cuts}</td>
        <td className="cupTeamRowBase text-center">&nbsp;</td>
      </tr>
    )
  });

  return (
    <Table className="completedEntriesTable">
      <thead>
        <tr className="completedEntriesTableHeader">
          <th className="text-center">POS</th>
          <th className="">Event</th>
          {props.oldCupScoring === false &&
            <th className="text-center">Points</th>
          }
          <th className="text-center">To Par</th>
          <th className="text-center">Cuts</th>
          <th className="text-center">&nbsp;</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </Table>
  )
}


const CupStandings = (props) => {

  const cupRows = [];

  if (props.data){
    props.data.forEach((item, index) => {
      cupRows.push(<CupStandingsRow item={item} key={index} index={index} numWinners={props.numWinners}
        cupScoring2ndPhase={props.cupScoring2ndPhase} scoringMethod={props.scoringMethod}
        selectedLeague={props.selectedLeague}/>);
    });
  }


  const seasons = props.cups.map((data) =>
    <DropdownItem key={data.cupid} onClick={() => props.setCupId(data.cupid, data.season)}>
      {data.season}
    </DropdownItem>
  )

  let showTeam = true;
  if (props.selectedLeague && props.selectedLeague.whiteLabel && !props.selectedLeague.whiteLabel.use_team_names){
    showTeam = false;
  }

  return (
    <Card className="my-4">
      <CardTitle className="pl-2 pr-3 py-3 m-0 d-flex justify-content-between font-weight-bold" style={{fontSize: '1rem'}} >
        Cup Standings

        <Dropdown isOpen={props.seasonDropdownOpen} toggle={() => props.toggle('seasonDropdownOpen')} size="sm">
          <DropdownToggle caret className="golferModalOptionRow_dropdowntoggle">
            {props.season}
          </DropdownToggle>
          <DropdownMenu>
            {seasons}
          </DropdownMenu>
        </Dropdown>
      </CardTitle>
      <CardBody className="p-0">
        <Collapse isOpen={props.standingsOpen}>
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="text-center">POS</th>
                <th>{showTeam?'Team (Owner)':'Team Owner'}</th>
                <th className="text-center text-nowrap">{props.cupScoring2ndPhase === 'ON' && props.scoringMethod !== 'Gross Score'?'Points':'To Par'}
                  {props.cupScoring2ndPhase === 'ON' &&
                    <CupScoringInfoModal embedIn={'CommissionerCup'} cupScorePoints={props.cupScorePoints} scoringMethod={props.scoringMethod}/>
                  }
                </th>
                <th className="text-center">Today</th>
                <th className="text-center">Behind</th>
                <th className="text-center">Events</th>
                <th className="text-center">&nbsp;</th>
              </tr>
            </thead>
            <tbody style={{fontSize: ".9em"}}>
              {cupRows}
            </tbody>
          </Table>
        </Collapse>
      </CardBody>
    </Card>
  )
}

const CupEvents = (props) => {
  if (!props.events){return '';}
  const cupRows = props.events.map((item, index) => {
    let badge;
    if (item.status === "live") {
      badge = <Badge color="primary"  style={{paddingLeft: "0.5rem"}}>LIVE</Badge>
    } else if (item.status === "open") {
      badge = <Badge color="warning"  style={{paddingLeft: "0.5rem"}}>OPEN</Badge>
    }

    let event = (item.status !== 'scheduled') ?
      <NavLink to={"/events/"+item.eventid}>
        {item.title} {badge}
      </NavLink> :
      item.title

    return (
      <tr key={index}>
        <td>{event}</td>
        <td>{item.dates}</td>
      </tr>
    )
  });

  return (
    <Card className="my-4">
      <CardTitle className="pl-2 pr-3 py-3 m-0 d-flex justify-content-between font-weight-bold" style={{fontSize: '1rem'}} onClick={()=>{props.toggle('eventsOpen')}}>
        Cup Events
        <FontAwesome size="lg" name={props.eventsOpen ? "angle-up" : "angle-down"} />
      </CardTitle>
      <CardBody className="p-0">
        <Collapse isOpen={props.eventsOpen}>
          <Table responsive className="mb-0">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th>Event</th>
                <th>Dates</th>
              </tr>
            </thead>
            <tbody style={{fontSize: '0.9em'}}>
              {cupRows}
            </tbody>
          </Table>
        </Collapse>
      </CardBody>
    </Card>
  )
}
