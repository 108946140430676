import React from 'react';

import {
  Alert,
  Badge,
  Card,
  Collapse,
  Row, Col,
  Input,
  Button,
  Table,
  Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';


import FontAwesome from 'react-fontawesome';
import LoadingModal from 'common/LoadingModal.js';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import { Bootstrap2Toggle } from 'react-bootstrap-toggle';
import "utils/bootstrap2-toggle.css";


import {OneAndDoneGameRuleAuth} from 'pages/user/Games.js';
import {MobilePortrait } from 'utils/responsive.js';


import {
  MobileHeader,
  ContentWrapper
} from 'common/CommissionerComponents.js';


import { get_lc_data, league_in_contest, set_lc_fld_value, event_in_lc} from 'utils/api.js';

export default class OneAndDone extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      enrolled: false,
      howToOpen: false,
      contestType: 'ONE_AND_DONE',
      eventsModal: false,
      ruleOpen: false,
    };


  }

  componentDidMount() {
    if (this.props.activeLeague === null) { return; }

    this.fetchEnrolledData(this.props.activeLeague);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.activeLeague === nextProps.activeLeague) { return; }

    this.fetchEnrolledData(nextProps.activeLeague);
  }


  fetchEnrolledData = (league) => {
    if (!league) { return; }
    this.setState({isLoading:true});
    get_lc_data(league, this.state.contestType,
    ({success, enrolled, lc, events, prevteams, availableGolfers, error}) => {
      if (success) {
        if (events){
          events.map((x) => x.actionsOpen = false);
        }

        this.setState({isLoading:false, enrolled, howToOpen: !enrolled, lc, events,});
      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }


  handleLCEnroll = (enrolled) => {
    if (enrolled === this.state.enrolled) { return; }

    const leaguein = (name) => {
      const league = this.props.activeLeague;
      league_in_contest(league, this.state.contestType, name, enrolled,
        (success, error) => {
          if (success) {
            this.fetchEnrolledData(league);
          } else {
            console.log(error);
          }
      }, (error) => {
        console.log(error);
      });
    }

    if (!enrolled){
      confirmAlert({
        title: 'Confirmation',
        message: 'Are you sure you want to cancel the One-and-Done?',
        buttons: [
          {
            label: 'Yes',
            onClick: () => { leaguein();}
          },
          {
            label: 'Cancel',
            onClick: () => { }
          }
        ]
      });
    }else{
      leaguein();
    }
  }


  toggleHowTo = () => {
    this.setState({
      howToOpen: !this.state.howToOpen, ruleOpen: this.state.howToOpen ? false: this.state.ruleOpen
    })
  }


  handleInputValueChange = (event) => {
    //this.setState({[event.target.name]: event.target.value});
    this.saveLCFieldValue(event.target.name,event.target.value);
  }


  saveLCFieldValue = (fldNm, fldVal) => {
    let lc = this.state.lc;
    lc[fldNm] = fldVal;
    this.setState({lc});
    set_lc_fld_value(this.props.activeLeague, this.state.lc.id, fldNm, fldVal,
    ({success, error}) => {
      if (success) {


      } else {
        console.log(error);
      }
    }, (error) => {
      console.log(error);
    });
  }


  toggleEventsModal = () => {
    this.setState({eventsModal : !this.state.eventsModal});
  }

  toggleEventRowActions = (eventid) => {
    let events = this.state.events;
    if (!events) { return; }
    const mtchEvent = events.find(({id}) => id === eventid);
    mtchEvent.actionsOpen = !mtchEvent.actionsOpen;
    this.setState({
      events:events,
    });
  }

  handleEventToggle = (eventid, enrolled) => {
    if (!this.state.events) { return; }
    //if (this.state.events.find(({id, enrolled:currEnrolled}) => id === eventid && enrolled === currEnrolled)) { return; }

    const league = this.props.activeLeague;
    this.setState({isLoading:true});
    event_in_lc(league, this.state.lc.id, eventid, enrolled,
      (success, error) => {
        this.setState({isLoading:false});
        if (success) {
          let events = this.state.events;
          if (eventid > 0){
            let mEvent = events.find(({id}) => id === eventid);
            mEvent.enrolled = enrolled;
          }else{
            events.map((e)=>e.enrolled=enrolled);
          }
          this.setState(events);
          this.fetchEnrolledData(league);
        } else {
          console.log(error);
        }
    }, (error) => {
      console.log(error);
    });
  }

  toggleAddEditTeamModal = (show, teamid) => {
    this.setState({error : null});
    if (this.state.lc.eventsCnt ===0){
      confirmAlert({
        title: 'Stop',
        message: 'Please choose tournaments before adding teams',
        buttons: [
          {
            label: 'Ok',
            onClick: () => { }
          }
        ]
      });
      return;
    }

    if (this.state.addEditTeamModal === show){return;}
    if (show === true){
      this.setState({addEditTeamModal : true});
      if (teamid){ //edit existing team
        this.editTeamGolfer(teamid);
      }else{
        this.addTeamGolfer();
      }
      return;
    }
    if (this.state.addEditTeamModal && this.state.teamid > 0){
      let team = this.state.lc.teamGolfers.filter(x => x.teamid===this.state.teamid)[0];
      let selectedGolferList = []
      team.golgers.forEach((g, index) => {
        selectedGolferList.push({teamgolfer_id:g.teamgolfer_id, golfer_id: g.golfer_id,golfer_name:g.golfer_name,image:'', alt:g.alt,
                            first_contestevent_id: g.first_contestevent_id, last_contestevent_id: g.last_contestevent_id
                              });
      });
      /*if (JSON.stringify(selectedGolferList) !== JSON.stringify(this.state.selectedGolferList)){
        confirmAlert({
          title: 'Confirm',
          message: 'Unsaved data will be lost. Continue?',
          buttons: [
            {
              label: 'Continue',
              onClick: () => {this.setState({addEditTeamModal : !this.state.addEditTeamModal});}
            },
            {
              label: 'Save',
              onClick: () => {this.saveTeamGolfers();}
            }
          ]
        });
        return;
      }*/
    }
    this.setState({addEditTeamModal : !this.state.addEditTeamModal});
  }


  toggleRuleOpen = () =>{
    this.setState({ruleOpen: !this.state.ruleOpen});
  }

  render() {
    return (
      <div className="commissionerMembers">
        <MobileHeader to="One-and-Done" leagueid={this.props.activeLeague} />
        <ContentWrapper>
          <LCHeader />
          <LCSubheader howToOpen={this.state.howToOpen} ruleOpen={this.state.ruleOpen} toggle={this.toggleHowTo} toggleRuleOpen={this.toggleRuleOpen}/>

          <LCParticipation {...this.state} leagueid={this.props.activeLeague}
            handleLCEnroll={this.handleLCEnroll}  handleInputValueChange={this.handleInputValueChange}
            saveLCFieldValue={this.saveLCFieldValue}
            toggleEventsModal={this.toggleEventsModal}
            handleEventToggle={this.handleEventToggle}
            toggleEventRowActions={this.toggleEventRowActions}
            toggleAddEditTeamModal={this.toggleAddEditTeamModal}
            toggleChoosePrevTeamModal={this.toggleChoosePrevTeamModal}
            setTeamName={this.setTeamName}
            selectGolfer={this.selectGolfer}
            unSelectGolfer={this.unSelectGolfer}
            toggleChooseGolferModal={this.toggleChooseGolferModal}
            saveTeamGolfers={this.saveTeamGolfers}
            removeTeamGolfers={this.removeTeamGolfers}
            />


          <LCFooter />
          <LoadingModal modalScroll={true} isLoading={this.state.isLoading} />

        </ContentWrapper>
      </div>
    );
  }
}

const LCHeader = () =>
  <div className="d-flex mb-4">
    <div className="d-none d-lg-block mr-auto" style={{fontSize: "1.75em", fontWeight: 300}}>One-and-Done</div>
  </div>

const LCSubheader = (props) =>
  <Alert color="success" className="my-4">
    <div className="d-flex justify-content-between py-1 font-weight-bold" onClick={props.toggle}>
      One-and-Done Instructions
      <FontAwesome size="lg" name={props.howToOpen ? "angle-up" : "angle-down"} />
    </div>
    <Collapse isOpen={props.howToOpen}>
      <span style={{"fontSize": "0.80rem", whiteSpace: "pre-line"}}>
        Welcome to One-and-Done Fantasy Golf! In this competition, players select a PGA TOUR golfer each week, and their earnings from that event add to the player’s season-long total. The catch? Once a golfer is chosen, they can't be picked again. The person with the highest total earnings at the end of the PGA TOUR season wins. This game takes patience and strategy, knowing when to pick the best golfers and when and where to find value each week.
        &nbsp;<span style={{color: "#157EFB", cursor: "pointer"}} onClick={props.toggleRuleOpen}>Click here </span>for full rules.
      </span>
    </Collapse>
    <Collapse isOpen={props.ruleOpen}  className="my-n4">
      <OneAndDoneGameRuleAuth embedIn={'COMMISS'}/>
    </Collapse>
  </Alert>

const LCFooter = () =>
  <div className="d-flex justify-content-center py-1 font-weight-bold ml-2 text-danger" >
        {/*New Season Starting in 2024*/}
  </div>

const LCParticipation = (props) =>{

  const numericOptions = [{cd:'1',label:'1'},{cd:'2',label:'2'},{cd:'3',label:'3'},{cd:'4',label:'4'}].map((sts, index) =>
    <option value={sts.cd} key={index}>{sts.label}</option>
  );

  return(
  <Card body className="p-2 my-4">
    <div className="pl-2 py-2 d-flex align-items-center border-bottom">
        <div className="col-sm-8 pt-1">
          {'Participate in the One-and-Done?'}
        </div>
        <div className="col-sm-4 d-flex justify-content-end">
          <Bootstrap2Toggle
            onClick={(state)=>{props.handleLCEnroll(state)}}
            on={'YES'}
            off={'NO'}
            size="sm"
            offstyle="danger"
            onstyle="success"
            active={props.enrolled}
          />
        </div>
    </div>
    {props.enrolled &&
        <div className="pl-2 pt-1">

          <Row noGutters className=" pl-3 py-1 mr-1 border-bottom">
            <Col xs="12" className="">
              <div className="mb-2" style={{fontSize: ".95rem"}}>
                {'One-and-Done Announcement'}
              </div>
              <Input type="textarea" placeholder="Message to display." style={{fontSize:'.8rem'}} name="msg"
                value={props.lc.msg} onChange={props.handleInputValueChange} rows="6"/>
              <div style={{fontSize: "0.75em", color: "#888"}} className="my-2">
                {''}
              </div>

              {/*
              <Button outline className="btn-sm mt-2" color="primary" onClick={() => {props.toggleAddEditTeamModal(true);}} >Add Team</Button>
              <AddEditTeamGolfer {...props}/>
              <TeamGolfersTable enrolled={props.enrolled} teamGolfers={props.lc.teamGolfers} toggleAddEditTeamModal={props.toggleAddEditTeamModal}/>
              */}
            </Col>
          </Row>
          {/*
          <div className="pl-2 py-2 d-flex">
              <div className="col-sm-8 pt-2">
                {'Maximum Teams Allowed Per User'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0">
                  {'This option permits users to create more than one entry. Each entry is independent and runs all season long.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
                <Input type="select"  value={props.lc.maxTeamsPerUser} name="maxTeamsPerUser" style={{width:'65px'}}
                  onChange={props.handleInputValueChange}>
                  {numericOptions}
                </Input>
              </div>
          </div>
          */}
          <div className="pl-2 py-2 d-flex">
              <div className="col-sm-8 pt-1">
                {'Allow New Players Mid-Season'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0">
                  {'Keep this setting open for your first event, and consider locking mid-season to prevent new players from joining. When locked, only existing members continue playing.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
              <Bootstrap2Toggle
                onClick={(state)=>{props.saveLCFieldValue('allowNewTeams',state)}}
                on={'Yes'}
                off={'Locked'}
                size="sm"
                offstyle="danger"
                onstyle="success"
                active={props.lc.allowNewTeams}
              />
              </div>
          </div>
          <div className="pl-2 py-2 d-flex border-bottom">
              <div className="col-sm-8 pt-1">
                {'Selections Per Week'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0">
                  {'The number of golfers to choose on each team per week. Once a golfer is chosen, it cannot be used again on that team.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
                <Input type="select"  value={props.lc.noGolfersPerWk} name="noGolfersPerWk" style={{width:'65px'}}
                  onChange={props.handleInputValueChange}>
                  {numericOptions}
                </Input>
              </div>
          </div>
          <div className="pl-2 py-2 d-flex align-items-center">
              <div className="col-sm-8 pt-1">
                You have <strong>{props.lc.eventsCnt}</strong> tournaments in your season
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
                <Button outline className="btn-sm mt-2" color="primary" onClick={props.toggleEventsModal} >Manage Tournaments</Button>
                {/*<Button outline className="btn-sm mt-2" color="primary" onClick={props.toggleEventsModal} >Manage Tournaments
                  <Modal isOpen={props.eventsModal} toggle={props.toggleEventsModal} className="inviteModal">
                    <ModalHeader toggle={props.toggleEventsModal} className="mcModalHeader">Tournaments</ModalHeader>
                        <ModalBody>
                          <EventsTable {...props} handleEventToggle={props.handleEventToggle}
                            toggleEventRowActions={props.toggleEventRowActions}/>

                        </ModalBody>
                    <ModalFooter>
                    </ModalFooter>
                  </Modal>
                </Button>*/}
              </div>
          </div>
          {props.eventsModal &&
            <div className="pl-2 py-2 d-flex align-items-center border-bottom">
              <EventsTable {...props} handleEventToggle={props.handleEventToggle}
                toggleEventRowActions={props.toggleEventRowActions}/>
            </div>
          }

          {/*
          <div className="pl-2 py-2 d-flex border-bottom ">
              <div className="col-sm-8 pt-1">
                {'Maximum Repeats'}
                <div style={{fontSize: "0.75em", color: "#888"}} className="my-0">
                  {'The number of times a specific golfer can be selected during the season.'}
                </div>
              </div>
              <div className="col-sm-4 d-flex justify-content-end">
                <Input type="select"  value={props.lc.maxSameGolferRepeat} name="maxSameGolferRepeat" style={{width:'65px'}}
                  onChange={props.handleInputValueChange}>
                  {numericOptions}
                </Input>
              </div>
          </div>
          */}
        </div>
    }
  </Card>
  );
}


const EventsTable = ({enrolled, events, handleEventToggle, toggleEventRowActions}) => {

  if (!enrolled) { return null; }

  const rows = events.map((event, index) => <EventRow {...event} key={event.id} indx={index} handleEventToggle={handleEventToggle} toggleEventRowActions={toggleEventRowActions}/>)
  const eventsCnt = events.length;
  const enrolledEveCnt = events.filter((e)=> {return e.enrolled}).length;
  const allEventsEnrolled = eventsCnt>enrolledEveCnt?false:true;

  return (
    <Card body className="p-0 mt-4">
    <div className="p-2 d-flex justify-content-between">
      <div className="mt-1" style={{color: "#555", fontWeight:700}}>Tournaments this Season</div>
      <div className="">
        <Button block color="success"  onClick={()=>{handleEventToggle(0, !allEventsEnrolled);}}
                style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase", minWidth: "138px"}} className="pt-2 pb-2">
                {allEventsEnrolled ? 'Unregister All' : 'Register All'}
        </Button>
      </div>
    </div>
      <Table responsive className="">
        <thead>
          <tr style={{backgroundColor: "#f6f6f6", color: "#7c7c7c", "fontSize": "0.7rem", textTransform: "uppercase"}}>
            <th>Tournament</th>
            <th className="text-center text-nowrap d-none d-sm-table-cell" style={{width:'32%'}}>One-and-Done Event</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </Table>
    </Card>
  )
}

const EventRow = (props) => {
  const {name, dates, course, major, status} = props;

  const tournament =
    <td>
        <div style={{color: "#555", fontWeight:700}}>
          <span className="text-nowrap">{name}</span>
          { major &&
            <Badge color="success" style={{marginLeft: "0.5rem"}}>Major</Badge>
          }
          { status === "open" &&
            <Badge color="warning" style={{marginLeft: "0.5rem"}}> Open </Badge>
          }
          { ["round1", "round2", "round3", "round4"].indexOf(status) !== -1 &&
            <Badge color="info" style={{marginLeft: "0.5rem"}}> Live </Badge>
          }
        </div>
        <div style={{color: "#aaa", fontSize: ".75em"}}>{dates} &bull; {course}</div>
        <MobilePortrait>
          <IncludeOrRemoveMob {...props} />
        </MobilePortrait>
    </td>

    return (
      <tr>
        {tournament}
        <td className="d-none d-sm-table-cell"><IncludeOrRemove {...props} /></td>
      </tr>
    )
}


const IncludeOrRemove = ({enrolled, status, id, actionsOpen, handleEventToggle, hasEntries, indx, toggleEventRowActions}) => {
  const toggle = () => {toggleEventRowActions(id);};
  if (['round1', 'round2', 'round3', 'round4','closed'].indexOf(status) > -1 && !enrolled){
    return <Button block color="secondary" outline
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="pt-2 pb-2">
                  {'CLOSED'}
          </Button>;
  }
  if (enrolled){
    return <div className="d-flex justify-content-around align-items-center">
          <div style={{width:'50%'}}>
          <Button block color="success" outline onClick={()=>{handleEventToggle(id, !enrolled);}}
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="text-nowrap">
                  {'Remove'}
          </Button>
          </div>
          <div style={{width:'50%'}} className="pl-2">
          <Button block color="success" outline
                  style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="text-nowrap">
                  {'EDIT'}
          </Button>
          </div>

          </div>
  }
  return <Button block color="success" outline={enrolled} onClick={()=>{handleEventToggle(id, !enrolled);}}
                style={{fontWeight: 600, fontSize: ".85em", textTransform:"uppercase"}} className="pt-2 pb-2">
                {enrolled ? 'Remove' : 'Register'}
        </Button>;
}

const IncludeOrRemoveMob = ({enrolled, status, id, actionsOpen, handleEventToggle, hasEntries, indx, toggleEventRowActions}) => {
  const toggle = () => {toggleEventRowActions(id);};
  if (['round1', 'round2', 'round3', 'round4','closed'].indexOf(status) > -1 && !enrolled){
    return <div className="d-flex justify-content-end align-items-center mt-2 mr-2">
            <div>
              <Button size="sm" color="secondary" outline className="btn-no-border"
                >
                CLOSED
              </Button>
            </div>
          </div>;
  }
  if (enrolled){
    return <div className="d-flex justify-content-end align-items-center mt-2 mr-2">
          <div>
            <Button size="sm" color="success" outline className="btn-only-buttom-border" onClick={()=>{handleEventToggle(id, !enrolled);}}
              >
              <FontAwesome name="remove" />
            </Button>
          </div>
          <div className="pl-1">
            <Button size="sm" color="success" outline className="btn-only-buttom-border">
              <FontAwesome name="pencil" />
            </Button>
          </div>

          </div>
  }
  return <div className="d-flex justify-content-end align-items-center mt-2 mr-2">
          <div>
            <Button size="sm" color="success" outline className="btn-only-buttom-border" onClick={()=>{handleEventToggle(id, !enrolled);}}
              >
              <FontAwesome name="plus" />
            </Button>
          </div>
        </div>
}
