import React from 'react';

import {
  Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
  Row, Col, Button,
} from 'reactstrap';

import NumberFormat from 'react-number-format';

import FontAwesome from 'react-fontawesome';

import {
  ContentWrapper
} from 'common/ProfileComponents.js';

import { locationStates } from 'utils/constants';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import 'pages/user/JoinLeague.css';


import ImgCCAmex from 'img/cc_amex.png';
import ImgCCVisa from 'img/cc_visa.png';
import ImgCCMaster from 'img/cc_master.png';
import ImgCCDiscover from 'img/cc_discover.png';

import {
  authrize_user_payment,
} from 'utils/api.js';

export default class AuthnetPay extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: props.amount,
      loading: false,
      status: null,
      saveSuccess: null,
      error: null,
      stateDropdownOpen: false,
      message:null,
      cardHolderName:'',
      cardNmbr:'',
      expiryDate:'',
      cvv:'',
      cardHolderState:'',
      cardHolderZip:'',

    }


    this.handleChange = this.handleChange.bind(this);
    this.toggleStateDropdown = this.toggleStateDropdown.bind(this);
    this.handleStateSelect = this.handleStateSelect.bind(this);
    this.handleCardPaymentSubmit = this.handleCardPaymentSubmit.bind(this);

  }
  componentDidMount() {

  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.amount !== this.props.amount){
      this.setState({amount: this.props.amount,});
    }

  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleStateSelect(state) {
    this.setState({cardHolderState:state});
  }

  toggleStateDropdown() {
    this.setState({stateDropdownOpen: !this.state.stateDropdownOpen});
  }



  handleCardPaymentSubmit(event) {
    if (this.props.preValidation){
      let ret = this.props.preValidation();
      if (!ret){return;}
    }
    this.setState({loading: false, error:null, message: null, status:null});
    const cardholder = this.state.cardHolderName.trim();
    const cardNmbr = this.state.cardNmbr.replace(/\s+/g, '').trim();
    let expiryDate = this.state.expiryDate.trim();
    const cvv = this.state.cvv.trim();
    const cardHolderZip = this.state.cardHolderZip.trim();
    const cardHolderState = this.state.cardHolderState;
    const amount = this.state.amount;
    var getCardType = function (number) {
        var cards = {
            visa: /^4/, // /^4[0-9]{12}(?:[0-9]{3})?$/,
            mastercard: /^5[1-5]/, //  /^5[1-5][0-9]{14}$/,
            amex: /^3[47]/,
            diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
            discover: /^6(?:011|5[0-9]{2})/, // /^6(?:011|5[0-9]{2})[0-9]{12}$/,
            jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
            unknown: /[0-9]/
        };
        for (var card in cards) {
            if (cards[card].test(number)) {
                return card;
            }
        }
    };
    let cardType = getCardType(cardNmbr.replace(/\s+/g, '').trim());
    if (!cardType || cardType === 'unknown'){
      this.setState({loading: false, error:'Invalid Credit Card Type.'});
      return;
    }
    if (this.props.leagueEventId && this.props.leagueEventId > 0){
      if (!cardholder || !cardNmbr || !expiryDate || !cvv || !cardHolderZip || !cardHolderState){
        this.setState({loading: false, error:'Please provide all the details.'});
        return;
      }
    }else{
      if (!cardholder || !cardNmbr || !expiryDate || !cvv){
        this.setState({loading: false, error:'Please provide all the details.'});
        return;
      }
    }
    /*if ((cardType === 'amex' && cardNmbr.length !== 15) ||
      (cardType !== 'amex' && cardNmbr.length !== 16) ||
      (cardType === 'amex' && cvv.length !== 4) ||
      (cardType !== 'amex' && cvv.length !== 3) ||
      expiryDate.length !== 5){
      this.setState({loading: false, error:'Incomplete Card details.'});
      return;
    }*/

    expiryDate = '20' + expiryDate.split('/')[1] + '-' + expiryDate.split('/')[0];

    confirmAlert({
      title: 'Confirm',
      message: 'Your card will be charged $' + amount + '. Continue?',
      buttons: [
        {
          label: 'Cancel',
          onClick: () => {
          }
        },
        {
          label: 'OK',
          onClick: () => {
            this.setState({loading: true});
            authrize_user_payment(amount, cardholder, cardNmbr, expiryDate, cvv, cardHolderState, cardHolderZip, cardType.toUpperCase(),this.props.leagueId, this.props.leagueEventId, this.props.entryId,
            ({success, error, paymentRes, me}) => {
                console.log(paymentRes);
                this.setState({loading: false, status: paymentRes.status,
                    message:paymentRes.messageDesc,
                    transId:paymentRes.transId,
                    authCode:paymentRes.authCode,
                  });

                if (paymentRes.status === 'success'){
                  this.props.setPaymentStatus('success', '$' + this.props.amount + ' is Successfully deposited to MC', paymentRes.userLedgerId);
                }else if (paymentRes.status === 'fail'){
                  this.setState({error: 'Sorry, the transaction was declined.'});
                }else if (paymentRes.status === 'alreadypaid'){
                  this.setState({error: 'No payment is needed. Entry is already paid.'});
                }
              }, (error) => {
                console.log(error);
                this.setState({loading: false, error:error.message});
              });

          }
        }
      ]
    });


  }



  renderCardInputForm() {



    const stateDropdown = <Dropdown isOpen={this.state.stateDropdownOpen} toggle={this.toggleStateDropdown} size="sm">
        <DropdownToggle color="white" className="border profileFormRow_border col-md-12 mt-2" outline caret>
          {this.state.cardHolderState || 'Select a State'}
        </DropdownToggle>
        <DropdownMenu style={{maxHeight: '200px', overflowY: 'scroll'}} className="col-md-12">
          <DropdownItem name="cardHolderState" onClick={()=>this.handleStateSelect('')} style={{color: 'gray'}}>
            (clear)
          </DropdownItem>
          {locationStates.map((name) =>
            <DropdownItem key={name} name="cardHolderState" onClick={()=>this.handleStateSelect(name)}>
              {name !== null ? name : '(none)'}
            </DropdownItem>)}
        </DropdownMenu>
      </Dropdown>;

    const limit = function (val, max) {
      if (val.length === 1 && val[0] > max[0]) {
        val = '0' + val;
      }

      if (val.length === 2) {
        if (Number(val) === 0) {
          val = '01';

        //this can happen when user paste number
      } else if (val > max) {
          val = max;
        }
      }

      return val;
     };

     const cardExpiry = function (val) {
       let month = limit(val.substring(0, 2), '12');
        let year = val.substring(2, 4);

        return month + (year.length ? '/' + year : '');
      };

      var getCardType = function (number) {
          var cards = {
              visa: /^4/, // /^4[0-9]{12}(?:[0-9]{3})?$/,
              mastercard: /^5[1-5]/, //  /^5[1-5][0-9]{14}$/,
              amex: /^3[47]/,
              diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
              discover: /^6(?:011|5[0-9]{2})/, // /^6(?:011|5[0-9]{2})[0-9]{12}$/,
              jcb: /^(?:2131|1800|35\d{3})\d{11}$/
          };
          for (var card in cards) {
              if (cards[card].test(number)) {
                  return card;
              }
          }
      };


    let cardType = getCardType(this.state.cardNmbr.replace(/\s+/g, '').trim());
    let ImgCCType = (cardType==='visa'?ImgCCVisa:(cardType==='mastercard'?ImgCCMaster:(cardType==='amex'?ImgCCAmex:(cardType==='discover'?ImgCCDiscover:null))));
    return (
      <div className="formContainer justify-content-center cash-table-container card card-body py-1">
        {this.props.goBack &&
        <Row className="" style={{backgroundColor:'#38485C', borderTopLeftRadius:'4px',borderTopRightRadius:'4px'}}>
          <div className="d-flex justify-content-start px-3 pb-1" style={{width:'100%'}}>
            <div className="d-flex" style={{color:"#ffffff", marginTop:'12px', cursor:'pointer'}}
              onClick={() => this.props.goBack()}  >
              <i className ="arrow left"></i>
              <div style={{marginTop:'-8px'}}>Back</div>
            </div>
          </div>
        </Row>
        }


        <form onSubmit={this.handleCardPaymentSubmit}>
          <Row className="">
            <Col className="formContent_row formContent_row_no_bottom_border formContent_row_top_round mt-1">
              <label className="formContent_formInput_label">CARD HOLDER NAME</label>
              <input type="text" name='cardHolderName' value={this.state.cardHolderName} className="formContent_formInput"
                onChange={this.handleChange}  placeholder="e.g. John Smith"/>
            </Col>
          </Row>
          <Row className="">
            <Col className="formContent_row formContent_row_no_bottom_border">
              <label className="formContent_formInput_label">CARD NUMBER</label>
              <div className="d-flex justify-content-between">
              <NumberFormat value={this.state.cardNmbr} format="#### #### #### ####" name='cardNmbr'
                onChange={this.handleChange} className="formContent_formInput" placeholder="e.g. 1234 5678 9012 3456"
                style={{width:'90%', paddingTop:'2px'}} />
                {ImgCCType && <img src={ImgCCType} alt="Card Issuer" style={{height: "22px"}} className=""/>}
                {!ImgCCType && <FontAwesome name="credit-card" size="2x" style={{color:'gray'}}/>}
              </div>
            </Col>
          </Row>
          <Row className="">
            <Col className="formContent_row formContent_row_bottom_left_round formContent_row_no_right_border">
              <label className="formContent_formInput_label">EXPIRY DATE</label>
              <NumberFormat  name='expiryDate' value={this.state.expiryDate} className="formContent_formInput"
                onChange={this.handleChange}  format={cardExpiry} placeholder="MM/YY" mask={['M', 'M', 'Y', 'Y']}/>
            </Col>
            <Col className="formContent_row formContent_row_bottom_right_round">
              <label className="formContent_formInput_label">CVV</label>
              <NumberFormat name='cvv' value={this.state.cvv} className="formContent_formInput"
                onChange={this.handleChange}  placeholder="e.g 123" format='####' />
            </Col>
          </Row>

          {(!!this.props.leagueEventId && this.props.leagueEventId > 0) &&
          <Row  className=" pt-4">
            <Col className="formContent_row formContent_row_bottom_left_round formContent_row_top_left_round formContent_row_no_right_border">
              <label className="formContent_formInput_label">STATE / PROVINCE</label>
              {stateDropdown}
            </Col>
            <Col className="formContent_row formContent_row_bottom_right_round formContent_row_top_right_round">
              <label className="formContent_formInput_label">ZIP CODE</label>
              <NumberFormat type="text" name='cardHolderZip' value={this.state.cardHolderZip} className="formContent_formInput"
                onChange={this.handleChange}  placeholder="e.g 12345" format='#####'/>
            </Col>
          </Row>
          }
          <input type="submit" value="Signup" hidden />
        </form>
        <Row className="justify-content-center text-center py-2">
          <Col style={{paddingLeft:'0',paddingRight:'0'}}>
            {
              this.state.loading === false ?
                <div className="d-flex justify-content-around">
                  {this.props.cancelPayment &&
                  <div style={{width:'50%'}} className="px-1">
                    <Button color="secondary" onClick={this.props.cancelPayment} block> Cancel</Button>
                  </div>
                  }
                  <div style={{width:(this.props.cancelPayment?'50%':'100%')}} className="px-1">
                    <Button color="success" onClick={this.handleCardPaymentSubmit} block> Deposit ${this.state.amount}</Button>
                  </div>
                </div>
              :
              <Button color="success" disabled block>
                <FontAwesome name="spinner" spin />
              </Button>
            }
          </Col>
        </Row>
        {this.state.error &&
        <Row className=" text-center py-1">
          <Col  style={{paddingLeft:'0',paddingRight:'0'}}>
            <div style={{color: "red", textAlign: "center", paddingBottom: "1rem"}}>{this.state.error}</div>
          </Col>
        </Row>
        }

      </div>
    )
  }


  render() {

    return (
      <div>
        <ContentWrapper>
          {this.renderCardInputForm()}
        </ContentWrapper>
      </div>
    )
  }
}
