import React from 'react';

import {
  Button,
  Container,
  Row,
  Col
} from 'reactstrap';

import {OnboardingWrapper} from 'common/LandingWrapper.js';
import LoginModal from 'common/LoginModal';

import 'pages/user/JoinLeague.css';
import 'pages/user/Signup.css';

import {
  check_password_reset,
  submit_password_reset,
} from 'utils/api.js';

export default class PasswordReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      code: props.match.params.code,
      valid: null,
      newPass: '',
      confirmNewPass: '',
      error: null,
      message: null,
      email: '',

    }
    this.renderPasswordReset = this.renderPasswordReset.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    //this.logg_out();
    check_password_reset(this.state.code, (response) => {
      let valid = response.success;
      if (valid){
        this.setState({valid: valid, email:response.useremail});
      }else{
        this.setState({valid: valid});
      }

    }, (error) => {
      this.setState({error: 'An unexpected error occurred. Has your password reset link expired?'});
    });
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }
  handleSubmit(event) {
    event.stopPropagation();
    event.preventDefault();
    submit_password_reset(this.state.code, this.state.newPass, this.state.confirmNewPass,
      (response) => {
        if (response.success) {
          this.setState({message: 'Your password has been changed. Sign in to get back to your leagues.',
          newPass: '', confirmNewPass:'',
          email:response.email, error: null});
        } else {
          this.setState({error: response.error})
        }
      }, (error) => {
        this.setState({error: 'An unexpected error occurred.'});
      }
    );
  }
  render() {
    if (this.state.valid) {
      return this.renderPasswordReset();
    }

    let message = "Checking your password reset code...";
    if (this.state.valid === false) {
      message = "Your password reset code was not valid.";
    }
    return (
      <OnboardingWrapper {...this.props}>
        <Container className="text-center landingCard mt-4">
          <h5>
            {message}
          </h5>
        </Container>
      </OnboardingWrapper>
    )
  }
  renderPasswordReset() {
    return (
      <OnboardingWrapper {...this.props}>
        <Container fluid className="joinContainer">
          <Row noGutters>
            <Col xs="12" sm="10" md="9" lg="8" xl="6" className="joinContent mx-auto">
              <h2 className="pb-1 text-center">{"Password Reset"}</h2>
              <form onSubmit={this.handleSubmit}>
              <Row noGutters className="justify-content-center">
                <Col xs="10" sm="9" md="9" lg="9" xl="8">
                  <label className="joinContent_signuplabel">
                    {this.state.email}
                  </label>
                </Col>
              </Row>
                <Row noGutters className="justify-content-center">
                  <Col xs="10" sm="9" md="9" lg="9" xl="8">
                    <label className="joinContent_signuplabel">
                      <input type="password" name="newPass" value={this.state.newPass}
                        className="joinContent_signupinput" onChange={this.handleChange}
                        placeholder="New Password"/>
                    </label>
                  </Col>
                </Row>
                <Row noGutters className="justify-content-center">
                  <Col xs="10" sm="9" md="9" lg="9" xl="8">
                    <label className="joinContent_signuplabel">
                      <input type="password" name="confirmNewPass" value={this.state.confirmNewPass}
                        className="joinContent_signupinput" onChange={this.handleChange}
                        placeholder="Confirm New Password"/>
                    </label>
                  </Col>
                </Row>

                <input type="submit" value="PasswordReset" hidden />
              </form>
              <Row className="justify-content-center text-right mt-1 mb-4">
                <Col xs="12" sm="9" md="8" lg="6" xl="5">
                  {
                    this.state.error && <div style={{color: "red", textAlign: "left", paddingBottom: "1rem"}}>{this.state.error}</div>
                  }
                  {
                    this.state.message ?
                      <div style={{textAlign: "center"}} className="mt-2">
                        <div style={{color: "#282", textAlign: "center", paddingBottom: "1rem"}}>{this.state.message}</div>
                        <LoginModal loginButtonActivation {...this.props} passedEmail={this.state.email}/>
                      </div> :
                      <div className="mt-3">
                        <div>
                          <Button color="success" onClick={this.handleSubmit} block> Reset Password </Button>
                        </div>
                      </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </OnboardingWrapper>
    )
  }
}
