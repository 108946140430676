import React from 'react';

import { DateTime } from 'luxon';
import {Intercom, hide, show, update} from '@intercom/messenger-js-sdk';

import {
  Desktop,
  NotDesktop
} from 'utils/responsive.js';

import {
  DesktopCommissioner,
  MobileCommissioner,
} from 'pages/commissioner/CommissionerNav.js';

import { get_commissionerdata } from 'utils/api.js';

import 'pages/commissioner/Commissioner.css';

export default class Commissioner extends React.Component {
  constructor(props) {
    super(props);

    let leaguename = undefined;
    let selectedLeague = null;
    for (var league of props.me.leagues) {
      if (league.leagueid === props.activeLeague) {
        leaguename = league.name;
        selectedLeague = league;
        break;
      }
    }

    this.state = {
      leaguename: leaguename,
      leagueid: props.league,
      leaguecreated: 'a great day.',
      selectedLeague,
    };

    this.fetchCommissionerData = this.fetchCommissionerData.bind(this);
    this.fetchCommissionerDataInterval = this.fetchCommissionerDataInterval.bind(this);
    this.interval = setInterval(this.fetchCommissionerDataInterval, 300*1000); // 5 minutes


  }

  componentDidMount() {
    let paramLeague = parseInt(this.props.match.params.league, 10);

    if (this.props.activeLeague !== paramLeague) {
      this.props.selectLeague(paramLeague);
    } else {
      this.fetchCommissionerData(this.props.activeLeague);
    }
  }

  componentWillReceiveProps(nextProps) {
    for (var league of nextProps.me.leagues) {
      if (league.leagueid === nextProps.activeLeague) {
        this.setState({
          leaguename: league.name,
          leagueid: league.leagueid,
          selectedLeague: league,
        });
        break;
      }
    }
    if (nextProps.activeLeague === this.props.activeLeague) {
      return
    }

    this.fetchCommissionerData(nextProps.activeLeague);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchCommissionerData(league) {
    get_commissionerdata(league,
    (data) => {
      if (!data.success) {
        if (data.error)
          alert(data.error);
        this.props.history.push('/');
        return;
      }
      this.setState({
        leaguename: data.leaguename,
        leaguecreated: DateTime.fromISO(data.leaguecreated, { zone: 'UTC' }).setZone('local').toLocaleString(),
        leaguebalance: data.leaguebalance,
        balancewarning: data.balancewarning,
        club: data.club,
        clubstatus: data.clubstatus,
        isItChildClub: data.isItChildClub,
        parentClubLeagueName: data.parentClubLeagueName,
      });
    }, (error) => {
      console.log(error);
    });
  }

  fetchCommissionerDataInterval() {
    this.fetchCommissionerData(this.props.activeLeague);
  }

  render() {
    update({hide_default_launcher: false,});
    hide();
    return (
      <div className="commissionerWrapper">
        <Desktop>
          <DesktopCommissioner activeLeague={this.props.activeLeague} fetchCommissionerData={this.fetchCommissionerData} {...this.state}
           updateMeDefaultEvent={this.props.updateMeDefaultEvent} updateMe={this.props.updateMe} me={this.props.me}/>
        </Desktop>
        <NotDesktop>
          <MobileCommissioner activeLeague={this.props.activeLeague} fetchCommissionerData={this.fetchCommissionerData} {...this.state}
          updateMeDefaultEvent={this.props.updateMeDefaultEvent} updateMe={this.props.updateMe} me={this.props.me}/>
        </NotDesktop>
      </div>
    );
  }
}
