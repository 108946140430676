import React from 'react';


import {
  Container, Row, Col,
  Card, CardTitle, CardBody,
  Table,
  Input,
  Button,
  //Modal, ModalHeader, ModalBody, ModalFooter,
} from 'reactstrap';

import { Link, } from 'react-router-dom';

import perfectTeamEnter from 'img/250kenter.png';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import 'common/ConfirmAlert.css';

import { isMobPortrait, } from 'utils/responsive.js';

//import AuthedJoinLeague from 'pages/user/AuthedJoinLeague';

import { get_golf_pools, join_public_pool_self, check_league } from 'utils/api.js';
import { CURRENT_SEASON } from 'utils/constants';



export default class JoinGolfPool extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      pools:[],
      season: CURRENT_SEASON(),
      privatePoolModal: false,
    };

  }

  componentDidMount() {
    this.fetchGolfPools();

  }
  componentDidUpdate(prevProps, prevState) {

  }

  componentWillReceiveProps(nextProps) {

  }

  fetchGolfPools = () =>{
    get_golf_pools(
      this.state.season,
    ({success, error, pools}) => {
      if (success) {
        this.setState({leading: false, pools: pools});
      } else {
        //alert(error);
      }
    }, (error) => {
      console.log(error);
    });

  }

  joinPublicPool = (leagueid) =>{
    this.setState({leading: true,});
    join_public_pool_self(
      leagueid,
    ({success, message, me}) => {
      if (success) {
        this.setState({leading: false,});
        this.props.updateMe(me);
        this.props.history.push('/league/'+leagueid);
        return;
      } else {
        if (message === 'Already Member'){
          this.props.history.push('/league/'+leagueid);
          return;
        }
        confirmAlert({
          title: 'Alert',
          message: message,
          buttons: [
            {
              label: 'Ok',
              onClick: () => { }
            }
          ]
        });
      }
    }, (error) => {
      console.log(error);
    });
  }

  handleInputValueChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  togglePrivatePoolJoinModal = () => {
    this.setState({privatePoolModal : !this.state.privatePoolModal});
  }

  checkLeague = () => {
    this.setState({leagueslugMsg:''});
    if (!this.state.leagueslug){
      this.setState({leagueslugMsg:'Enter the league code.'});
      return;
    }
    check_league(
      this.state.leagueslug,
      null,
    ({success, reason, leagueName, leagueType, commissioner, leagueid, commissionerId}) => {
      if (!success && reason === 'Invalid League ID'){
        this.setState({leagueslugMsg:reason});
        return;
      }
      if (success && leagueid !== null){
        if ('already member' === reason){
          //this.setState({leagueslugMsg:'You are already member of this pool.'});
          this.props.history.push('/league/'+leagueid);
          return;
        }

      }
      this.props.history.push('/ileague/'+this.state.leagueslug);
    }, (error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <Container fluid style={{maxWidth: "1128px"}} className="my-4 pt-4">
        <Row className="py-4">
          <Col xs="12" md={{size:"10", offset: "1"}} lg={{size:"8", offset:"2"}}>
            <PrivatePool leagueslug={this.state.leagueslug}
              leagueslugMsg={this.state.leagueslugMsg}
              handleInputValueChange={this.handleInputValueChange}
              checkLeague={this.checkLeague}/>

            <div className="text-center mx-0 my-2" >
              <Link to="/club/free" style={{color: "inherit", textDecoration: "none", cursor: "pointer"}}>
                <img src={perfectTeamEnter} alt="alt" width="100%" />
              </Link>
            </div>

            <PublicPool pools={this.state.pools} joinPublicPool={this.joinPublicPool}/>

            {/*<Modal isOpen={this.state.privatePoolModal} toggle={this.togglePrivatePoolJoinModal} className="inviteModal">
              <ModalHeader toggle={this.togglePrivatePoolJoinModal} className="mcModalHeader">Join Private Pool</ModalHeader>
                  <ModalBody>
                    <AuthedJoinLeague {...this.props} leagueslug={this.state.leagueCd} />
                  </ModalBody>
              <ModalFooter>
              </ModalFooter>
            </Modal>
            */}

          </Col>
        </Row>
      </Container>
    );
  }
}

const PrivatePool = (props) => {


  return (
    <Card className="my-4" style={{border:"0px"}}>
      <CardTitle className="pl-2 pr-3 py-1 m-0 d-flex justify-content-center font-weight-bold" style={{fontSize: '1.4rem'}} >
        Join a Private Golf Pool
      </CardTitle>
      <CardBody className="p-0">
          <div className="pl-2 py-2 d-flex justify-content-center">
            <div>
              <Input type="text" placeholder="Enter private league code"
                style={{fontSize:'.8rem', width:'200px', textAlign:'center'}} name="leagueslug"
                value={props.leagueslug} onChange={props.handleInputValueChange}/>
              {props.leagueslugMsg &&
                <div className="mt-1 text-danger font-weight-bold" style={{fontSize: ".75rem"}}>
                  {props.leagueslugMsg}
                </div>
              }
            </div>
            <div>
            <Button color="primary"  onClick={() => props.checkLeague()} size="sm" className="ml-2">
              <span className="" >
                {'Join'}
              </span>
            </Button>
            </div>

          </div>

      </CardBody>
    </Card>
  )
}

const PublicPool = (props) => {

  const poolRows = [];

  if (props.pools){
    props.pools.forEach((item, index) => {
      poolRows.push(<PublicPoolRow item={item} key={index} index={index} joinPublicPool={props.joinPublicPool}/>);
    });
  }


  return (
    <Card className="my-4" style={{border:"0px"}}>
      <CardTitle className="pl-2 pr-3 py-3 m-0 d-flex justify-content-center font-weight-bold" style={{fontSize: '1.4rem'}} >
        Join a Public Contest
      </CardTitle>
      <CardBody className="p-0">
          <Table responsive className="mb-0 border-left border-right border-bottom">
            <thead>
              <tr style={{backgroundColor: "#fafafa", color: "gray", "fontSize": "0.85rem"}}>
                <th className="text-left">League Name</th>
                <th className="text-center">Members</th>
                <th className="text-left">Roster</th>
                <th className="d-none d-md-block text-center">Season Contest </th>
                <th className="text-center">&nbsp;</th>
              </tr>
            </thead>
            <tbody style={{fontSize: (isMobPortrait()?"0.7em":"0.9em")}}>
              {poolRows}
            </tbody>
          </Table>

      </CardBody>
    </Card>
  )
}


class PublicPoolRow extends React.Component {
    constructor(props) {
      super(props);
      this.state = { collapsed: true };
      this.toggle = this.toggle.bind(this);
    }
    toggle() {
      this.setState({collapsed: !this.state.collapsed});
    }
    render() {
      const item = this.props.item;
      const index = this.props.index;
      const rosters = {
        12:"12 Golfers",
        11:"Best 11 Golfers",
        10:"Best 10 Golfers",
        9: "Best 9 Golfers",
        8: "Best 8 Golfers",
        7: "Best 7 Golfers"};


      return (
        <>
        <tr key={index}  className={"cupTeamRowBody"}>
          <td className="cupTeamRowBase text-left">{item.name}</td>
          <td className="cupTeamRowBase text-center">{item.activeMemberCnt}</td>
          <td className="cupTeamRowBase text-left">{rosters[item.bestGolfersScoreCnt]}</td>
          <td className="cupTeamRowBase text-center d-none d-md-block">{item.seasonContest}</td>
          <td className="align-middle">
            <span onClick={() => {this.props.joinPublicPool(item.id);}}  style={{color: "#157EFB", fontSize: ".85em", cursor: "pointer"}} >
            Join
            </span>
          </td>
        </tr>
        </>
      )
    }
}
