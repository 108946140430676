import React from 'react';
import {
  Modal, ModalHeader, ModalBody, ModalFooter,
  Button,
  Input,
} from 'reactstrap';

import {Intercom, hide, show, showSpace} from '@intercom/messenger-js-sdk';

import 'common/McModal.css'
import 'common/FeedbackModal.css';

export default class IntercomAIModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false,
    };

    this.toggle = this.toggle.bind(this);

  }

  toggle() {
    if (!this.state.modal) {

    }else{

    }
    show();
    this.setState({
      modal: !this.state.modal,
    });
  }




  render() {

    return <span>
      <span onClick={this.toggle} className="feedbackRenderComponent">
        {this.props.component}
      </span>

    </span>
  }
}
