import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'font-awesome/css/font-awesome.min.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';

//import TagManager from 'react-gtm-module';

//const tagManagerArgs = {
//    gtmId: 'GTM-TXFCXSR' //'GTM-5LS6964'
//}

//TagManager.initialize(tagManagerArgs);

//ReactDOM.render(<App />, document.getElementById('root'));

ReactDOM.render(
  <PayPalScriptProvider
    options={
      { "client-id": "AVsxWLPKn2nMYS3_PBy5Yw0T-UkTvScjENKlzMYw3pLzLfd0LKIsmlCFQYpFuNBwgkJUvsp71_4eMYJA",
      "currency": "USD",
      "intent": "capture",
      "disable-funding": "credit,card",
      "enable-funding": "venmo",
      //"buyer-country": "US",
      "locale": "en_US",
      "debug": true,}}>
    <App />
  </PayPalScriptProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
